import { faSquareRss } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { Pagination } from "../components/blog/Pagination"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import Head from "../components/security/Head"
import Reporting from "../components/security/Reporting"

// props
interface Props {
  data: {
    cves: {
      nodes: Array<{
        title: string
        slug: string
        severity: string
        fields: {
          publishedAt: string
        }
        description: {
          childMarkdownRemark: {
            excerpt: string
          }
        }
      }>
    }
  }
  pageContext: {
    limit: number
    skip: number
    numPages: number
    currentPage: number
    totalCount: number
  }
}

const Item = ({ cve }: any) => {
  return (
    <article className="item py-6">
      <h2 className="is-size-3 has-text-weight-semibold">
        <Link
          className="has-text-black"
          to={`/security/${cve.slug.toLowerCase()}`}
        >
          {cve.title}
        </Link>
      </h2>
      <div className="meta py-4">
        {cve.severity?.toUpperCase()} | {cve.fields.publishedAt.toUpperCase()} |{" "}
        {cve.slug.toUpperCase()}
      </div>
      <HTMLContent
        content={cve.description.childMarkdownRemark.excerpt}
        className={"content markdown"}
      ></HTMLContent>
      <div>
        <Link
          className="button is-spring"
          to={`/security/${cve.slug.toLowerCase()}`}
        >
          Read more
        </Link>
      </div>
    </article>
  )
}

// markup
const SecurityTemplate = (props: Props) => {
  return (
    <Layout className={"security"} seo={{ title: "Security Advisories" }}>
      <div className="container">
        <div className="security-content pb-4">
          <Head />
          <p className="links-animate">
            This page lists Spring advisories. Additional information on other
            VMware Products can be found on the{" "}
            <a href="https://www.vmware.com/security/advisories.html">
              VMware Security Advisories
            </a>{" "}
            page.
          </p>
        </div>
        <div className="columns">
          <div className="column is-8">
            <div className="content security-content pb-6">
              <div>
                {props.data.cves.nodes.map((cve: any, i: number) => {
                  return <Item cve={cve} key={`item${i}`} />
                })}
              </div>
              <Pagination
                path={`/security/`}
                currentPage={props.pageContext.currentPage}
                postsPerPage={props.pageContext.limit}
                totalCount={props.pageContext.totalCount}
              />
            </div>
          </div>
          <div className="column is-4 pt-6 mt-4">
            <Reporting />
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default SecurityTemplate

// graphQL queries
export const pageQuery = graphql`
  query SecurityTemplate($skip: Int!, $limit: Int!) {
    cves: allContentfulCvEs(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: fields___publishedAt }
    ) {
      nodes {
        title
        slug
        severity
        fields {
          publishedAt(formatString: "MMMM DD, YYYY")
        }
        description {
          childMarkdownRemark {
            excerpt(format: HTML, pruneLength: 250)
          }
        }
      }
    }
  }
`
